$light: #ffffffe6;
$dark: #2d3c49;
$highlight: #7d97ad;

body {
  margin: 0;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  color: $dark;
}

h1 {
  margin: 60px auto;
}

.intro-text {
  font-size: 2em;
}

/* *******************
    NAVIGATION/HEADER
***************** */
.nav-bar {
  height: 50px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 4px $highlight;
  background-color: $dark;
  color: $light;
}

header {
  float: left;
  margin-left: 10px;
  display: flex;
}

header img {
  width: 50px;
  height: 50px;
  margin-right: 8px;
}

.name {
  padding-left: 13px;
  border-left: 1px solid $highlight;
  text-transform: uppercase;
  font-weight: 100;
}

.main-heading {
  font-size: 30px;
  letter-spacing: 1px;
}

.sub-heading {
  font-size: 16px;
  padding-left: 4px;
}

#menuB {
  height: 50px;
  width: 50px;
  float: right;
  background-color: white;
}

nav {
  width: 50%;
  height: 150px;
  z-index: 1;
  background-color: transparent;
  opacity: 0.95;
  position: absolute;
  -webkit-transform: translate(100%, -175px);
  transform: translate(100%, -175px);
  transition: transform 0.3s ease;
}

nav.open {
  background-color: $light;
  -webkit-transform: translate(100%, 50px);
  transform: translate(100%, 50px);
}

.nav-bar a {
  text-decoration: none;
  color: $light;
}

.nav-link {
  letter-spacing: 1.2px;
}

li {
  list-style-type: none;
  line-height: 1.7em;
}

a:hover {
  color: #02b3e4;
}

/* ***********************
    Attn-grabber
************************ */
#attention {
  background-repeat: no-repeat;
  background-position-x: center;
  position: relative;
  margin-top: 5px;
  height: 288px;
  padding-top: 10%;
  background-size: cover;
  margin-bottom: -20px;
}

.intro-text {
  font-family: 'Lato', sans-serif;
  background-color: rgba(27, 27, 27, 0.788);
  color: $light;
  line-height: 1.5em;
  font-style: italic;
  padding-right: 0.25em;
}

.img-attribution {
  position: absolute;
  bottom: 0;
  right: 0;
}

/* ***********************
FEATURED WORK
*********************** */
.featured {
  margin-top: 20px;
  margin-left: 10px;
  color: $highlight;
  font-size: 18px;
  font-weight: 100;
  letter-spacing: 1px;
  width: 100%;
}

.featured-head {
  font-size: 20px;
}

.work-grid {
  display: flex;
  flex-wrap: wrap;
  max-width: 750px;
  margin: auto;
}

h3 {
  margin-top: 0;
  margin-bottom: 2em;
  padding-top: 1em;
  text-transform: uppercase;
  font-size: 18px;
  color: black;
}

.work-link {
  margin-top: 150px;
  margin-right: 10px;
  font-size: 14px;
  color: $light;
}

.my-work {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  color: #2d3c49;
}

.item {
  box-sizing: border-box;
  text-align: center;
  margin: 0 auto 1.5em auto;
  width: 300px;
  height: 300px;
  border: solid 1px $highlight;
}

.first {
  background-image: url('/img/memorygame_300px.png');
}

.second {
  background-image: url('/img/bugger_300px.png');
}

.third {
  background-image: url('/img/restaurant_review-300px.png');
}

.fourth {
  background-image: url('/img/book_app-300px.png');
}

.fifth {
  background-image: url('/img/rude_map-300px.png');
}

.cover {
  height: 300px;
  width: 300px;
  margin: 0;
  padding: 0;
  border: none;
  opacity: 0;
  background-color: #2d3c49;
  transition: opactiy 2s 0.5s;
}

.cover:hover {
  opacity: 0.95;
}

.work-img {
  width: 300px;
  height: 300px;
  max-width: 100%;
}

/* ***********************
    ABOUT
*********************** */
#about-me {
  background-color: #2d3c49;
  color: rgba(255, 255, 255, 0.9);
  font-family: 'Lato', sans-serif;
  text-align: center;
  padding: 50px 20px 50px 20px;
  font-size: 4em;
}

#about-me p {
  font-size: 0.3em;
}
